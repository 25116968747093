import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import Layout from "../components/layout/Layout";
import { graphql } from "gatsby";
import Img from "gatsby-image";

const IndexPage = ({ location, data }) => {
  return (<Layout location={location}>
      <div className="row justify-content-lg-center">
        <div className="col col-lg-8">
          <Img
            className={'mt-3'}
            sizes={data.file.childImageSharp.sizes}
            title={'cook with pom'}
            alt={'cook with pom'}
          />
        </div>
      </div>
      <div className="row justify-content-lg-center ">
        <div className="col col-lg-8">
          <p className={'lead mt-5'}>
            A half day (3 to 4 hours) private cooking class at my home.
            <br />
            Visit market and buy goods to cook later at my house.
            <br/>
            Prepare up to three dishes (depending on what you want to learn).
          </p>
          <p className={'lead mt-5 px-3'}>
            <strong>
              Price is 30 $ (1.000 THB) for one person or 45 $ (1.500 THB) for two
              persons.
            </strong>
          </p>
        </div>
      </div>

    </Layout>
  );
};
export default IndexPage;


export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "cookwithpom.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        sizes(maxWidth: 1900) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`;
